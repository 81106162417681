import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaRestBannerUpper: 'As one might expect, according to ',
    ParaRestBannerAnchor: 'an Accenture survey,',
    ShiftUrlBannerPara:
      'https://newsroom.accenture.com/news/cfos-need-greater-real-time-data-to-pivot-from-measuring-to-creating-value-amid-covid-19-accenture-report-finds.htm',
    ParaRestBannerLower:
      '99% of CFOs want to use real-time data when making decisions, even while only 16% believe they are fully capable of doing so. This also made the importance of credible real-time data for fast and informative decision-making a high priority for Finance executives. So, when Raindrop understood the criticality of CFO requirements for valuable and instantly accessible information drawn directly from large data sets, the solution for a fully integrated spend planning platform was started.',
    ParaOneUpeer:
      'Similar to how real-time data is changing the value proposition for Finance leaders, the demand is being delivered via new technologies simply not previously available. This reality in new capabilities is driven by “Finance leaders have been asking more and more about artificial intelligence (AI), machine learning, robotics, and advanced analytics,” says Alex Bant, Chief of Research, Finance, Gartner. According to ',
    ParaOneAnchor: 'Gartner,',
    ParaOneLower: 'the top four digitization initiatives for CFOs in 2021 and beyond are',
    ShiftAnchorUrl: 'https://www.gartner.com/smarterwithgartner/top-priorities-for-finance-leaders-in-2021/',
    ParaOnePointOne: 'Advanced data analytics technologies and tools in finance',
    ParaOnePointTwo: 'Workflow automation technologies',
    ParaOnePointThree: 'Accelerating digital skills',
    ParaOnePointFour: 'Enterprise-wide digital investment review and governance',
    ParaTwo:
      'While Raindrop recognizes the advanced data analytics technologies are at the forefront of Finance priorities, they also present a challenge. To help address and mitigate these key risk areas, Raindrop solves the need for real-time data and technologies as presented in this article',
    ParaTwoPointOneNumber: '⟶',
    ParaTwoPointOne: 'Gain control of your spend with Raindrop',
    ParaTwoPointTwoNumber: '⟶',
    ParaTwoPointTwo: 'Raindrop helps achieve critical digitization initiatives for Finance leaders',
    HeadingOne: 'Gain control of your spend with Raindrop',
    ParaThree:
      'Raindrop’s modern Enterprise Spend Management solution is powered by artificial intelligence (AI) and machine learning (ML), providing visibility and unparalleled capabilities in controlling how and who spends money at your company. Raindrop digitizes your Procurement processes and is created to focus on delivering efficiencies, be they cost savings, cost avoidance, or other programmatic benefits. Raindrop seamlessly and readily integrates with all major ERP systems, HR systems, and other central data sources to create a unified and normalized view of company spend. Raindrop’s easy-to-use interface enables users to address data as desired, further enabling you to create and share reports with several layers of analytics that are very easily accessible.',
    HeadingTwo: 'Raindrop helps achieve critical digitization initiatives for Finance leaders',
    HeadingTwoSubPointOne: '⟶  Advanced data analytics technologies and tools in finance',
    ParaFour:
      'In today’s era of digitization, just getting the data or analytics from spend management solutions is not enough. Ensuring clean and complete data is used as the starting point, and managing this between various technology systems (such as ERP, CMS, HR systems, etc.) is critical. After all, good and complete data results in good and complete outcomes. Raindrop advanced analytics helps to retrieve accurate data in real-time from multiple sources and provide a unified and normalized view with actionable insights.',
    ParaFive:
      'Raindrop enables you to have a unified spend data set for your financial planning needs by providing visibility into purchase commitments, commodity market trends, spend by supplier, region, function, etc. Raindrop provides a 360-degree view across different dimensions of spend, inclusive of',
    ParaFivePointOne: 'Commodity acquisition patterns, savings, and avoidance achieved',
    ParaFivePointTwo: 'Predicting opportunities and risk involved with external supplier spend',
    ParaFivePointThree:
      'Predicting future trends using machine learning on your own spend data sets and historical market performance',
    ParaFivePointFour:
      'Planning various scenarios such as mergers & acquisitions, annual operating plan, growth, and more',
    ParaFivePointFive:
      'Providing visibility into commodity consumption, recover costs from under-utilized commodities, and contain costs before over-consumption.',
    ParaFivePointSix:
      'Providing predictive capabilities via artificial intelligence (AI) and machine learning (ML) to reduce your overall spend',
    HeadingTwoSubPointTwo: '⟶  Workflow automation technologies',
    ParaSix:
      'Raindrop provides the necessary tools and workflows needed to control your spend by creating calls to action',
    ParaSixPointOne: 'When the incumbered funds are nearing full consumption',
    ParaSixPointTwo: 'When the commodity consumption is nearing the acquired amount',
    ParaSixPointThree: 'When accruals exceed budgeted amount',
    ParaSixPointFour: 'Contract routing and approval workflows with full audit trial',
    HeadingTwoSubPointThree: '⟶  Accelerate solution adoption',
    ParaSeven:
      'Developing digital skills is a prerequisite for finance leaders as organizations and the finance function itself accelerate the adoption of digital technologies. Ease of use is a desired element for any successful technology or business transformation.  Simply put, it drives platform adoption. If the system is complex and requires extensive training or set up effort, then it can act as a barrier to technology adoption and compliance, essentially negating the value proposition one sought to achieve in the first place. Raindrop’s powerful, intuitive, flexible, simple to navigate, and easy to adopt solution was designed to primarily address the current system adoption barriers.  It provides an intuitive user interface and tools which',
    ParaSevenPointOne: 'Increases collaboration between internal teams and suppliers',
    ParaSevenPointTwo:
      'Provides internal teams with a simplified view of their sourcing requests and who has the next action',
    ParaSevenPointThree: 'Seamlessly integrates with your internal collaborative software',
    HeadingTwoSubPointFour: '⟶  Enterprise wide digital investment review and governance',
    ParaEight:
      'Raindrop allows Finance leaders to have real-time visibility into enterprise spend and manage fiscal commitments proactively.  As a Finance executive, instantly gain spend visibility and seamlessly integrate yourself with the Procurement process to help control the money leaving your company',
    ParaEightPointOne:
      'Raindrop allows you to model different financial scenarios in a private environment and identifies cost reduction opportunities based on industry benchmarks, and informs you of upcoming renewals.',
    ParaEightPointTwo:
      'When ready, simply publish your chosen plan to your intended team members or groups, and consolidate individual departmental plans into an overall company spend plan.  Raindrop provides the aggregated plan throughout the enterprise, ensuring consistency in messaging and execution across organizational groups.',
    ParaEightPointThree:
      'With the power of Raindrop, you can achieve greater data visibility and governance identify, including areas for contract consolidation & preventing unnecessary purchases.',
    ParaEightPointFour:
      'Precise data tracking and contract renewal notifications help in preparation for negotiations, avoiding late payments, and securing the most beneficial terms for your company.',
    ParaEightPointFive:
      'Provides 360-degree supplier insights and transparency, who in your company are buying things, from which suppliers, and track where your contractual obligations are going to.',
    ParaEightPointSix:
      'Raindrop provides instant visibility to the financial, contractual, performance status, current news, and more, all designed to give the transparency you need to run your business, and to extract the most value from each supplier relationship.',
  },
]
