import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppFinanceLeadersRest } from './AppFinanceLeadersRest'

const AppFinanceLeadersWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .FinanceLeadersSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .FinanceLeadersNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .FinanceLeadersSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppFinanceLeadersWithNav = () => {
  return (
    <AppFinanceLeadersWithNavWapper>
      <div className='FinanceLeadersSection'>
        <div className='FinanceLeadersNav'>
          <AppSideNavEmailName />
        </div>
        <div className='FinanceLeadersSectionContent'>
          <AppFinanceLeadersRest />
        </div>
      </div>
    </AppFinanceLeadersWithNavWapper>
  )
}
