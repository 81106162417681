import React from 'react'
import { AppResourcesEmailBanner } from './AppResourcesEmailBanner'
import FinanceLeadersBannerData from '../Data/FinanceLeadersBannerData.json'
import styled from 'styled-components'

const AppFinanceLeadersBannerWapper = styled.div`
  .FinanceLeadersSection {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }
  .FinanceLeadersImageSection {
    @media (max-width: 1450px) {
      padding-top: 50px;
    }
    @media (max-width: 1090px) {
      margin: auto;
      padding-top: 30px;
    }
  }
  .FinanceLeadersHeading {
    max-width: 940px;
    font-size: 2.5rem;
    line-height: 1.30435em;
    padding: 0px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .FinanceLeadersPara {
    max-width: 850px;
    padding: 15px 30px 15px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .DateFinanceLeadersPara {
    max-width: 850px;
    color: #34a1d5;
    font-size: 19px;
    padding: 15px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
    }
  }
  .FinanceLeadersBannerImage {
    width: 100%;
    height: auto;
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border-radius: 18px;
  }
  .FinanceLeadersAnchor {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 6px;
    padding-left: 6px;
    text-decoration: none;
  }
  .FinanceLeadersAnchor:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .DisplayNone {
    display: none;
  }
`

export const AppFinanceLeadersBanner = () => {
  return (
    <AppFinanceLeadersBannerWapper>
      {FinanceLeadersBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppResourcesEmailBanner
              Heading={data.Title}
              DatePara={data.DatePara}
              ImagePath={data.ImagePath}
              AltText={data.AltText}
              LeadParaLeft={data.ParaLeft}
              LeadAnchorPara={data.anchorPara}
              LeadParaRight={data.ParaRight}
              ShiftUrlHref={data.ShiftUrlPath}
              achorPara='FinanceLeadersAnchor'
              BannerHeading='FinanceLeadersHeading'
              LeadParaStyle='FinanceLeadersPara'
              BannerPara='DisplayNone'
              DateBannerPara='DateFinanceLeadersPara'
              BannerImageSection='FinanceLeadersImageSection'
              BannerImage='FinanceLeadersBannerImage'
              BannerSection='FinanceLeadersSection'
            />
          </div>
        )
      })}
    </AppFinanceLeadersBannerWapper>
  )
}
